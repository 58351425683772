import React from 'react';

import {
  CustomItemType,
  CustomSectionType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { globalStyling } from '@vcc-package/leads-utils/constants';
import { cssJoin } from '@volvo-cars/css/utils';

import { NoticeBox, SectionHeading } from '../../flexible-forms/components';
import { CustomCheckboxField } from '../fields/custom/customCheckboxField';
import {
  useFlexFormContext,
  useFlexFormCustomSectionContext,
} from '../providers/FlexibleFormsProvider';
import { useLeadsContext, useTranslate } from '../../context';

function normalizeType(type: string) {
  let tmp = type.trim().toLowerCase();
  return tmp[0].toUpperCase() + tmp.substring(1);
}

export const CustomNamedSection = ({
  sectionName,
}: {
  sectionName: string;
}) => {
  const translate = useTranslate();
  const { hideRequired } = useFlexFormContext();
  const customSection = useFlexFormCustomSectionContext(sectionName);
  const { features } = useLeadsContext();

  if (!customSection || !translate) {
    return null;
  }

  const { title, type } = customSection;

  const customTitle =
    title === TranslationKey.PRIVACY && features.useNewCopyAndStyling
      ? TranslationKey.PRIVACY_POLICY
      : title;

  const fields = customSection.fields?.map((field) => {
    switch (normalizeType(field.type ?? '')) {
      case CustomItemType.TEXT: {
        return (
          <SectionHeading
            heading={customTitle ? translate(customTitle) : undefined}
            testId={sectionName}
            key={`${field.type}_${field.translationKey}`}
            headingClassName="mb-8 font-16"
            paragraphs={[translate(field.translationKey ?? '')]}
          />
        );
      }
      case CustomItemType.CHECKBOX: {
        return (
          <CustomCheckboxField
            translate={translate}
            field={field}
            key={`${field.type}_${field.translationKey}`}
            hideRequired={hideRequired}
          />
        );
      }
      default: {
        console.warn(`Type (${field.type}) is not recognized`);
        return null;
      }
    }
  });

  switch (normalizeType(type ?? '')) {
    case CustomSectionType.TEXT:
      return (
        <div
          className={cssJoin('flex flex-col', !title && globalStyling.fieldGap)}
        >
          {fields}
        </div>
      );
    case CustomSectionType.NOTICE:
      return (
        <div className="flex flex-col gap-8">
          {title && <p className="font-medium">{translate(title)}</p>}
          <NoticeBox>{fields}</NoticeBox>
        </div>
      );
    default:
      console.warn(
        `Custom section (${sectionName}) contains an invalid type (${type})`,
      );
      return null;
  }
};
