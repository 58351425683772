'use client';
import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, ErrorMessage } from '@volvo-cars/react-forms';
import type { TitleFieldProps } from '@vcc-package/leads-utils/types';
import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { getInputErrorMessages } from '@vcc-package/leads-utils/hooks';
import { globalStyling } from '@vcc-package/leads-utils/constants';

import { FieldLegend } from '../components/elements';
import { useLeadsContext } from '../../context';

type ContactSlotsFieldProps = Omit<TitleFieldProps, 'customTranslation'>;

export const ContactSlotsField = ({
  translate,
  options,
  hideRequired,
  validation,
}: ContactSlotsFieldProps): JSX.Element | null => {
  const { control } = useFormContext();
  const { features } = useLeadsContext();
  const messages = getInputErrorMessages(translate);
  const header = features.useNewCopyAndStyling
    ? translate(TranslationKey.BEST_TIME_TO_CONTACT)
    : translate(TranslationKey.CB_CONTACT_SLOTS_FIELD);
  const errorMessage = translate(TranslationKey.CB_SLOTS_REQUIRED_MESSAGE);
  const { ContactSlots } = options;
  const column1Indexes = Math.round(ContactSlots.length / 2);

  return (
    <div className={`flex flex-col ${globalStyling.fieldGap}`}>
      <FieldLegend
        title={header}
        required={validation.contactslots.required && !hideRequired}
      />
      <Controller
        control={control}
        defaultValue={[]}
        name={FlexFormFieldType.CONTACT_SLOTS}
        rules={{
          validate: (data: Array<string>) => {
            return data.length > 0
              ? true
              : messages?.required
                ? messages.required(errorMessage)
                : false;
          },
        }}
        render={({
          field: { onChange, value, onBlur, ref },
          fieldState: { error },
        }) => {
          let newValues: string[] = [...value];

          return (
            <div>
              <div
                className="grid-cols-2 gap-16"
                style={{ gridAutoFlow: 'row dense' }}
              >
                {ContactSlots.map((option, i) => {
                  return (
                    <Checkbox
                      name={option.label}
                      key={i}
                      data-testid={`contact-slots-${i}`}
                      label={option.label}
                      checked={newValues.indexOf(option.value) !== -1}
                      ref={ref}
                      style={{
                        gridColumn: i < column1Indexes ? 1 : 2,
                      }}
                      onChange={(e) => {
                        if (e.target.checked) {
                          newValues.push(option.value);
                        } else {
                          newValues.splice(newValues.indexOf(option.value), 1);
                        }
                        onChange(newValues);
                        onBlur();
                      }}
                    />
                  );
                })}
              </div>
              {error?.message ? (
                <ErrorMessage
                  errorMessage={error?.message}
                  className="mt-8"
                  id={FlexFormFieldType.CONTACT_SLOTS + '-error'}
                />
              ) : null}
            </div>
          );
        }}
      />
    </div>
  );
};
