'use client';
import type { PropsWithChildren } from 'react';
import React, { useCallback, useEffect, useMemo } from 'react';

import {
  FlexFormFieldType,
  PurposeType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import {
  StorageKey,
  convertStringToBoolean,
  createRequestFromFlexForm,
  getCampaignCode,
  getGtmFormTags,
  readFromSession,
  saveToSession,
  sendGASuccessEvent,
  type CreateRequestInput,
} from '@vcc-package/leads-utils/util';
import { useTracker } from '@volvo-cars/tracking';

import { LeadsApp } from '../constants';

import {
  AdditionalProps,
  FormLoadEventTracker,
  LeadsDefaultLayout,
  LeadsForm,
  LeadsFormSubmitHandler,
  useFeatureFlagTracker,
  useLeadsContext,
  useLeadsTracking,
  useModel,
  useRetailer,
  useTranslate,
} from '@vcc-package/leads-utils';
import type { EmbeddedFormProps } from './EmbeddedForm';

export const QuoteRequestForm = (
  props: PropsWithChildren<EmbeddedFormProps>,
) => {
  const track = useTracker();
  const leadsTracking = useLeadsTracking();
  const translate = useTranslate();

  const {
    analytics,
    API,
    formConfig,
    marketConfig,
    purpose,
    purposeConfig,
    setPurpose,
    siteSlug,
    urlData,
    isEmbeddableForms,
    consumerApp,
    navigation: { goTo, query, pathname },
    appId,
    features,
    publicRuntimeConfig,
  } = useLeadsContext();
  const { selectedModel } = useModel();
  const { isInsideSales, selectedRetailer } = useRetailer();
  const { getFeatureList } = useFeatureFlagTracker();

  const salesChannel = isInsideSales ? 'online' : undefined;
  const redirected = !!readFromSession(
    LeadsApp.QuoteRequest,
    StorageKey.redirected,
  );
  const isVbs = !!query.cceurl || !!query.vbscardatapath;
  const hideDealerPicker = !!selectedRetailer?.dealerId && !!query.retailerid;
  const hasRetailerSelection =
    formConfig?.order?.includes('dealerpicker') || selectedRetailer;
  const hasModelParams = !!query.pno || !!query.model || !!query.modelkey;

  useEffect(() => {
    if (isEmbeddableForms) {
      return;
    }

    if (!selectedModel && !hasModelParams) {
      console.warn('No model selected');
      props.OnError('MissingModel', new Error('No model selected'));
    } else if (!hasRetailerSelection && !query.retailerid) {
      console.warn('No retailer selected');
      props.OnError('MissingRetailer', new Error('No retailer selected'));
    }

    const newPurpose = isInsideSales
      ? PurposeType.CBV_CALLBACK
      : PurposeType.OFFER_REQUEST;
    if (purpose !== newPurpose) {
      setPurpose(newPurpose);
    }
  }, [
    isEmbeddableForms,
    isInsideSales,
    hasRetailerSelection,
    purpose,
    setPurpose,
    hasModelParams,
    props,
    query,
    selectedModel,
  ]);

  const b2bOptions = useMemo(
    () => ({
      shouldRender:
        convertStringToBoolean(query.b2b) || !!features.showB2bSection,
      isB2B: convertStringToBoolean(query.b2b),
    }),
    [features.showB2bSection, query.b2b],
  );

  const sendRequest: LeadsFormSubmitHandler = useCallback(
    async (formValues, recaptchaToken) => {
      const isB2B = Boolean(formValues.business) || b2bOptions.isB2B;
      const legalName = formValues?.legalname;

      const campaignCode = getCampaignCode({
        isB2B: !!legalName,
        purposeConfig,
        query,
      });

      if (selectedModel?.pno34) {
        formValues[FlexFormFieldType.PRODUCTS_OF_INTEREST] = [
          selectedModel.pno34,
        ];
      }

      if (!purpose) {
        throw new Error('No purpose selected');
      }

      const createRequestInput: CreateRequestInput = {
        formValues,
        marketId: siteSlug,
        languageId: marketConfig.lang ?? 'en',
        appId,
        query,
        retailerInformation: {
          retailerId: selectedRetailer?.dealerId ?? formValues.retailerid,
          customRetailerId: query.customretailerid,
        },
        additionalInformation: {
          recaptchaToken,
          campaignCode,
          analytics,
          urlData,
          business: isB2B,
        },
        consumerApp: consumerApp ?? pathname ?? 'unkown',
        embeddableFormsAdditionalInfo: props.additionalInfo,
        isEmbeddableForms,
        publicRuntimeConfig,
      };
      const request = createRequestFromFlexForm(createRequestInput);

      saveToSession(LeadsApp.QuoteRequest, StorageKey.submitData, request);

      const trackingData = leadsTracking?.formSubmit
        ? await leadsTracking.formSubmit({ b2b: isB2B })
        : undefined;
      const response = await API.sendRequest(request);

      if (response) {
        const { formId, formType } = getGtmFormTags({
          appId: LeadsApp.QuoteRequest,
          isB2b: isB2B,
          isVbs: isVbs,
          marketId: request.marketId,
        });

        track.interaction({
          carDriveLineType: selectedModel?.gtm?.powerTrain,
          carModelName: selectedModel?.gtm?.carModel,
          deeplink: redirected,
          eventAction: (isVbs ? 'vbs ' : '') + 'quote input',
          eventCategory: 'request a quote',
          eventLabel: 'success',
          salesChannel: salesChannel,
          ...getFeatureList(),
        });
        sendGASuccessEvent(formId, formType, salesChannel);
        if (trackingData) {
          track.formSubmit(trackingData);
        }

        const redirectUrl: string | undefined =
          response?.redirectUrl || query.redirecturl;

        if (redirectUrl && !isEmbeddableForms) {
          goTo(redirectUrl, { fullNavigation: true, external: true });
        } else {
          props.OnSubmit(request, response);
        }
      }
    },
    [
      b2bOptions.isB2B,
      purposeConfig,
      query,
      selectedModel?.pno34,
      selectedModel?.gtm?.powerTrain,
      selectedModel?.gtm?.carModel,
      purpose,
      siteSlug,
      marketConfig.lang,
      appId,
      selectedRetailer?.dealerId,
      analytics,
      urlData,
      consumerApp,
      pathname,
      props,
      publicRuntimeConfig,
      leadsTracking,
      API,
      isVbs,
      track,
      redirected,
      salesChannel,
      getFeatureList,
      isEmbeddableForms,
      goTo,
    ],
  );

  const additionalParameters: AdditionalProps = {
    b2b: b2bOptions,
    hideDealerPicker,
    hideFields: isInsideSales ? [FlexFormFieldType.PRODUCTS_OF_INTEREST] : [],
    useSingleColumn: props.inOverlay ?? purpose === PurposeType.OFFER_REQUEST,
    inOverlay: props.inOverlay,
  };

  const mainHeader = formConfig?.header?.heading
    ? translate(formConfig.header.heading)
    : formConfig?.header?.heading ??
      translate(TranslationKey.CONTACT_INFORMATION);
  const subHeaderTranslationKey =
    formConfig?.header?.subHeading === null
      ? null
      : TranslationKey.REQUEST_QUOTE;
  const subHeader = subHeaderTranslationKey
    ? translate(subHeaderTranslationKey)
    : undefined;

  return (
    <>
      <LeadsDefaultLayout
        heading={mainHeader}
        subHeading={subHeader}
        hideModel={props.hideModelImage ?? false}
      >
        <LeadsForm
          additionalProps={additionalParameters}
          onSubmit={sendRequest}
          onError={(errors, setLoading) => {
            setLoading(false);
            props.OnError('General', errors);
          }}
        >
          {props.children}
        </LeadsForm>
      </LeadsDefaultLayout>
      {!props.inOverlay && (
        <FormLoadEventTracker
          isB2b={b2bOptions.isB2B}
          isVbs={isVbs}
          salesChannel={salesChannel}
        />
      )}
    </>
  );
};
