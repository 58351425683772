'use client';

import React, { useEffect, useRef } from 'react';
import NextLink from 'next/link';
import { Track } from '@volvo-cars/tracking';
import { urlFromString, useVolvoCarsUrl } from '@vcc-www/volvo-cars-url';
import type { LinkProps, LinksProps } from './Links.view';
import { cssJoin } from '@volvo-cars/css/utils';
import styles from '../SubNavigation.module.css';
import { useIsMounted } from '@vcc-www/hooks/useIsMounted';

export const Link: React.FC<
  LinkProps & Pick<LinksProps, 'theme'> & { isInView: boolean }
> = ({
  text,
  href,
  eventLabel,
  accessibilityLabel,
  isInView,
  sources,
  theme,
}) => {
  const { contentPath: currentUrlPath } = useVolvoCarsUrl();

  const hasMounted = useIsMounted();
  const linkRef = useRef<HTMLAnchorElement>(null);
  const hasScrolledIntoView = useRef(false);

  const { contentPath: linkPath } = urlFromString(href);
  const isCurrent = currentUrlPath === linkPath;

  useEffect(() => {
    if (
      isCurrent &&
      linkRef.current &&
      hasMounted &&
      isInView &&
      !hasScrolledIntoView.current
    ) {
      linkRef.current.scrollIntoView({
        block: 'nearest',
        inline: 'end',
      });

      hasScrolledIntoView.current = true;
    }
  }, [isCurrent, hasMounted, isInView]);

  return (
    <Track eventLabel={eventLabel}>
      <NextLink
        href={href}
        className={cssJoin(
          'py-4 px-8 rounded whitespace-nowrap snap-center',
          styles.link,
          theme === 'light' && styles.lightLink,
        )}
        aria-label={accessibilityLabel}
        aria-current={isCurrent ? 'page' : undefined}
        ref={linkRef}
        data-sources={
          sources
            ? [sources?.text, sources?.href, sources?.accessibilityLabel]
            : undefined
        }
      >
        {text}
      </NextLink>
    </Track>
  );
};
