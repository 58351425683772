'use client';
import React from 'react';

import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { getInputErrorMessages } from '@vcc-package/leads-utils/hooks';
import type {
  B2BOptions,
  CustomTranslations,
  Translator,
} from '@vcc-package/leads-utils/types';
import {
  FlexFormTestID,
  NonApiFieldName,
} from '@vcc-package/leads-utils/types';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import { useFormContext } from 'react-hook-form';

import { CheckboxField, TextField } from '../../flexible-forms/components';
import { useLeadsContext } from '../../context';

type B2BFormProps = {
  translate: Translator;
  options: B2BOptions;
  customTranslation: CustomTranslations;
  hideRequired?: boolean;
};

export const B2BField = ({
  translate,
  options,
  customTranslation,
  hideRequired,
}: B2BFormProps): JSX.Element | null => {
  const { control, watch } = useFormContext();
  const messages = getInputErrorMessages(translate);
  const shouldLegalNameFormShow = watch('business');
  const labels = {
    b2bCheckbox: getTranslationKey(
      customTranslation,
      FlexFormFieldType.B2B,
      NonApiFieldName.Business,
    ),
    legalName: getTranslationKey(
      customTranslation,
      FlexFormFieldType.B2B,
      NonApiFieldName.LegalName,
    ),
  };
  const { features } = useLeadsContext();

  return (
    <div className="flex flex-col gap-16">
      {!options.isB2B && (
        <CheckboxField
          hideRequired={hideRequired}
          name={NonApiFieldName.Business}
          testid={FlexFormTestID.Business}
          control={control}
          label={
            features.useNewCopyAndStyling
              ? translate(TranslationKey.IAM_BUSINESS_CUSTOMER)
              : labels.b2bCheckbox
                ? translate(labels.b2bCheckbox.label)
                : translate(TranslationKey.IS_B2B_LABEL)
          }
          required={false}
          key="business"
          className="font-16"
        />
      )}
      {(shouldLegalNameFormShow || options.isB2B) && (
        <TextField
          hideRequired={hideRequired}
          key="legalName"
          name={NonApiFieldName.LegalName}
          testid={FlexFormTestID.LegalName}
          label={
            labels.legalName
              ? translate(labels.legalName.label)
              : translate(TranslationKey.LEGAL_NAME_FIELD_LABEL)
          }
          control={control}
          messages={messages}
          required
        />
      )}
    </div>
  );
};
