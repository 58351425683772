'use client';
import React, { useState } from 'react';

import type {
  EmailInputProps,
  TelInputProps,
  TextInputProps,
} from '@volvo-cars/react-forms';
import type { Control, FieldErrors } from 'react-hook-form';
import type { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { Controller } from 'react-hook-form';
import { EmailInput, TelInput, TextInput } from '@volvo-cars/react-forms';
import { FlexFormFieldType } from '@vcc-package/leads-utils/api';
import type { ValidationCallback } from '@vcc-package/leads-utils/hooks';
import { PhoneValidation } from '@vcc-package/leads-utils/types';

import type { FieldValidation, Messages } from '../types';
import { ValidationMethods } from '../types';
import { useRequiredLabel } from '../../../../hooks/useRequiredLabel';

export type ValidatedFieldProps = FieldValidation & {
  name: string;
  testid?: string;
  label: string;
  control: Control;
  validate: ValidationCallback;
  defaultValue?: string;
  messages?: Messages;
  errors?: FieldErrors;
  allowManualPhoneValidation?: boolean;
  setPhoneValidation?: (values: any) => void;
  hideRequired?: boolean;
  hint?: string;
  className?: string;
  invalidMessageType?: 'invalid' | 'digitsOnly' | 'fourDigitsOnly';
} & (
    | {
        inputType: 'Text';
        autoComplete?: TextInputProps['autoComplete'];
      }
    | {
        inputType: 'Email';
        autoComplete?: EmailInputProps['autoComplete'];
      }
    | {
        inputType: 'Tel';
        autoComplete?: TelInputProps['autoComplete'];
      }
  );

const inputTypeMap = {
  Text: TextInput,
  Email: EmailInput,
  Tel: TelInput,
};

export const ValidatedField = ({
  name,
  testid,
  defaultValue = '',
  label,
  autoComplete,
  control,
  required = false,
  minLength: minLengthFromConfig = 0,
  maxLength: maxLengthFromConfig = 25,
  validate,
  messages,
  hideRequired = false,
  type: validationType,
  allowManualPhoneValidation,
  setPhoneValidation,
  inputType,
  hint,
  className,
  invalidMessageType = 'invalid',
}: ValidatedFieldProps) => {
  const DynamicType = inputTypeMap[inputType];
  const [nonValidatedPhoneNumber, setNonValidatedPhoneNumber] = useState('');
  const formattedLabel = useRequiredLabel(label, required, hideRequired, false);

  const minLength = Number.isInteger(minLengthFromConfig)
    ? minLengthFromConfig
    : 0;
  const maxLength =
    Number.isInteger(maxLengthFromConfig) && maxLengthFromConfig !== 0
      ? maxLengthFromConfig
      : 25;

  const rules: RegisterOptions = {
    required: {
      value: required,
      message: messages?.required ? messages.required(label) : '',
    },
    minLength: {
      value: minLength,
      message: messages?.length
        ? messages.length(label, minLength, maxLength)
        : '',
    },
    maxLength: {
      value: maxLength,
      message: messages?.length
        ? messages.length(label, minLength, maxLength)
        : '',
    },
    validate: async (value: string) => {
      if (!value && !required) return true;

      const success = await validate(value);

      // phoneValidation should be set for gbgPhone
      if (
        name === FlexFormFieldType.MOBILE_PHONE &&
        validationType === ValidationMethods.gbgPhone
      ) {
        return validatePhone(success, value);
      }

      return success
        ? true
        : messages?.[invalidMessageType]
          ? messages[invalidMessageType]?.(label)
          : false;
    },
  };

  const validatePhone = (success: boolean, value: string) => {
    if (success) {
      if (setPhoneValidation) {
        setPhoneValidation(PhoneValidation.EXTERNAL);
      }
    } else {
      if (
        allowManualPhoneValidation &&
        setPhoneValidation &&
        new RegExp('^[-,+]{0,1}\\d*\\.{0,1}\\d+$').test(value)
      ) {
        if (value === nonValidatedPhoneNumber) {
          setPhoneValidation(PhoneValidation.INTERNAL);
          return true;
        } else {
          setNonValidatedPhoneNumber(value);
        }
      }
    }
    return success ? true : messages?.invalid ? messages.invalid(label) : false;
  };

  return !DynamicType ? null : (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error },
      }) => (
        <DynamicType
          ref={ref}
          name={name}
          data-testid={testid}
          label={formattedLabel}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          autoComplete={autoComplete as any}
          errorMessage={error?.message}
          spellCheck={false}
          hint={!error ? hint : undefined}
          className={className}
        />
      )}
    />
  );
};
