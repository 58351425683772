import type { PropsWithChildren } from 'react';
import React, { useCallback, useState } from 'react';
import { LeadsApp } from '../constants';

import type {
  RequestApiModel,
  RequestResponseApiModel,
} from '@vcc-package/leads-utils/api';
import { PurposeType, TranslationKey } from '@vcc-package/leads-utils/api';

import { useSetOverlayTitle } from '../hooks/useSetOverlayTitle';
import type { EmbeddableFormsWrapperProps } from './EmbeddableFormsWrapper';
import { EmbeddedForm } from './EmbeddedForm';
import { QuotePurposeSelector } from './QuotePurposeSelector';
import {
  LeadsContextProperties,
  useTranslate,
  MasterThankYouPage,
  Spinner,
  useLeadsContext,
} from '@vcc-package/leads-utils';

type EmbeddableFormsPartialProps = PropsWithChildren<{
  value: LeadsContextProperties | undefined;
  parentProps: PropsWithChildren<EmbeddableFormsWrapperProps>;
}>;
const EmbeddableFormsPartial = ({
  value,
  parentProps,
  children,
}: EmbeddableFormsPartialProps) => {
  const { isLoaded, isEmbeddableForms } = useLeadsContext();
  const isQR = value?.appId === LeadsApp.QuoteRequest;
  const [submitData, setSubmitData] = useState<RequestApiModel | null>(null);
  const inOverlay = parentProps?.setOverlayTitle !== undefined;
  const translate = useTranslate();

  const { features, purpose } = useLeadsContext();

  const title = translate(
    (purpose === PurposeType.KEEP_ME_UPDATED && features?.useDynamicTitle) ||
      (features?.showSubHeadingOnEF && purpose === PurposeType.OFFER_REQUEST)
      ? TranslationKey.OVERLAY_DIALOG_TITLE
      : value?.formConfig?.header.subHeading ||
          TranslationKey.REDES_RETAILER_MAP_SUB_HEADING,
  );

  useSetOverlayTitle(parentProps?.setOverlayTitle, title ?? '', inOverlay);

  const OnError =
    parentProps.OnError ??
    ((type, e) => {
      console.error(type, e);
    });

  const OnSubmitWrapped = useCallback(
    (submitData: RequestApiModel, apiResponse: RequestResponseApiModel) => {
      setSubmitData(submitData);

      if (parentProps.OnSubmit) {
        parentProps.OnSubmit(submitData, apiResponse);
      }
    },
    [parentProps],
  );

  if (!isLoaded) {
    const extraStyles = isEmbeddableForms
      ? undefined
      : {
          height: '100vh',
        };
    return <Spinner style={extraStyles} />;
  }

  if (submitData) {
    return <MasterThankYouPage />;
  }

  if (isQR) {
    return (
      <div className="flex flex-col mx-auto w-full">
        {parentProps.childrenPosition === 'top' ? children : <></>}
        <QuotePurposeSelector inOverlay={inOverlay}>
          <EmbeddedForm
            OnError={OnError}
            OnSubmit={OnSubmitWrapped}
            hideModelImage
            additionalInfo={parentProps.additionalInfo}
            inOverlay={inOverlay}
          />
        </QuotePurposeSelector>
        {parentProps.childrenPosition === 'bottom' ? children : <></>}
      </div>
    );
  }

  return (
    <EmbeddedForm
      OnError={OnError}
      OnSubmit={OnSubmitWrapped}
      additionalInfo={parentProps.additionalInfo}
      hideModelImage
      inOverlay={inOverlay}
    />
  );
};

export default EmbeddableFormsPartial;
