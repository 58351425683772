'use client';

import type { PropsWithChildren } from 'react';
import React from 'react';

import type { Translations, Translator } from '../../types';
import { getTranslationsObject } from '../../util';
import { useCreateTranslator } from '../../hooks/useCreateTranslator';
import { useLeadsContext } from './leadsContext';

export const TranslateContext = React.createContext<Translator>(null as any);
export const useTranslate = () => React.useContext(TranslateContext);

export const TranslateContextProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const {
    publicRuntimeConfig: { appEnv },
    fullConfig,
  } = useLeadsContext();
  const hideMissingTranslationMessage = appEnv === 'prod';

  const translate = useCreateTranslator({
    dictionary: (fullConfig?.translations
      ? getTranslationsObject(fullConfig.translations)
      : {}) as Translations,
    hideMissingTranslationMessage,
  });

  return (
    <TranslateContext.Provider value={translate}>
      {children}
    </TranslateContext.Provider>
  );
};
