import type { PropsWithChildren } from 'react';
import React from 'react';

import type { JSONObject } from '@vcc-package/leads-utils/types';
import {
  type RequestApiModel,
  type RequestResponseApiModel,
} from '@vcc-package/leads-utils/api';

import { FormPage } from './GenericFormComponent';
import { useLeadsContext, Spinner } from '@vcc-package/leads-utils';
import { EmbeddedFormErrorType } from './types';
import { LeadsApp } from '../constants';
import { QuoteRequestForm } from './QuoteRequestForm';

export type EmbeddedFormProps = {
  OnSubmit(
    submitData: RequestApiModel,
    apiResponse: RequestResponseApiModel,
  ): void;
  OnError(type: EmbeddedFormErrorType, e: Error): void;
  hideModelImage?: boolean;
  additionalInfo?: JSONObject;
  inOverlay?: boolean;
};

export const EmbeddedForm = (props: PropsWithChildren<EmbeddedFormProps>) => {
  const { appId, features, isLoaded, isEmbeddableForms } = useLeadsContext();

  if (!isLoaded) {
    const extraStyles = isEmbeddableForms
      ? undefined
      : {
          height: '100vh',
        };
    return <Spinner style={extraStyles} />;
  }

  const useQuoteRequestForm = appId === LeadsApp.QuoteRequest;

  return useQuoteRequestForm && !features.useNewQuoteFlow ? (
    <QuoteRequestForm {...props} />
  ) : (
    <FormPage {...props} />
  );
};
