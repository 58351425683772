'use client';
import React from 'react';

import { useFormContext } from 'react-hook-form';
import type { FieldProps } from '@vcc-package/leads-utils/types';
import {
  FlexFormTestID,
  NonApiFieldName,
} from '@vcc-package/leads-utils/types';
import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import { getInputErrorMessages } from '@vcc-package/leads-utils/hooks';
import { TextAreaField } from '../../flexible-forms/components';
import { useLeadsContext } from '../../context';

export const NotesField = ({
  translate,
  validation,
  customTranslation,
  hideRequired,
}: FieldProps): JSX.Element | null => {
  const { control } = useFormContext();
  const messages = getInputErrorMessages(translate);
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormFieldType.NOTES,
  );
  const { features } = useLeadsContext();
  const showNotesWithHeader = features?.notesWithHeader ?? false;

  /* TODO change */
  const label = features?.useNewCopyAndStyling
    ? translate(TranslationKey.ADDITIONAL_NOTES_FIELD)
    : customTranslationKeys
      ? translate(customTranslationKeys.label)
      : translate(TranslationKey.ADDITIONAL_NOTES_FIELD_LABEL);

  return (
    <>
      {showNotesWithHeader && (
        <h2 className="font-20 font-medium mt-8">
          {translate(TranslationKey.ADDITIONAL_NOTES_TITLE)}
        </h2>
      )}
      <TextAreaField
        hideRequired={hideRequired}
        key="description"
        name={NonApiFieldName.Description}
        testid={FlexFormTestID.Notes}
        label={label}
        description={
          customTranslationKeys?.description
            ? translate(customTranslationKeys.description)
            : undefined
        }
        control={control}
        messages={messages}
        {...validation.notes}
      />
    </>
  );
};
