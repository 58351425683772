'use client';
import * as React from 'react';

import { TranslationKey } from '@vcc-package/leads-utils/api';
import { LeadsApp } from '@vcc-package/leads-utils/constants';

import { useLeadsContext } from '../../../context/leadsContext';
import { useTranslate } from '../../../context';

const GroupingLabel = (): JSX.Element => {
  const translate = useTranslate();
  const { marketConfig, appId } = useLeadsContext();

  //FIXME: Will fix then FBEv5 has the new customtranslation handling in its translation endpoint
  if (appId !== LeadsApp.QuoteRequest || marketConfig.market !== 'uk') {
    throw new Error('GroupingLabel only supports Quote in UK until FBEv5');
  }

  if (!translate) {
    throw new Error('No translate provided from useFlexFormContext');
  }

  return (
    <p className="flex w-full micro font-medium">
      {translate(TranslationKey.PREFERRED_CONTACT_CHANNEL)}
    </p>
  );
};

export default GroupingLabel;
