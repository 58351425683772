/* eslint-disable @next/next/no-img-element */
'use client';

import type { LegacyRef } from 'react';
import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { cssMerge } from '@volvo-cars/css/utils';
import type {
  MarketVehicleConfiguration,
  MarketVehicleImage,
  MarketVehiclePresentation,
} from '@vcc-package/leads-utils/api';
import { VehicleImageType } from '@vcc-package/leads-utils/api';
import {
  requestId,
  useAssetPath,
  useModelImageSourceByType,
  useModelThankYouImageSource,
} from '@vcc-package/leads-utils/util';
import { useIsVisible } from '@vcc-package/leads-utils/hooks';

import { useLeadsContext } from '../../context/leadsContext';
import { embeddableFormsApiBaseUrl } from '@vcc-package/embeddable-forms';

export interface LeadsImageSize {
  type: 'max' | 'min' | 'none';
  screenWidth: number;
  imageWidth: number;
}
export interface LeadsTYImageProps {
  image?: MarketVehicleImage;
  alt?: string;
  sizes?: LeadsImageSize[];
}

export interface LeadsModelImageProps {
  model: MarketVehicleConfiguration;
  alt?: string;
  sizes?: LeadsImageSize[];
  type?: VehicleImageType;
  className?: string;
  imageClassName?: string;
}

export interface LocalImageLoaderProps {
  imageLoader: (src: string, width?: number, quality?: number) => string;
  src: string;
  sizes?: LeadsImageSize[];
}

const width4k = 3840;

const buildSizes = (sizes?: LeadsImageSize[]): string | undefined => {
  if (!sizes || !Array.isArray(sizes)) {
    return;
  }

  return sizes
    .map(({ imageWidth, screenWidth, type }) => {
      const width = type === 'max' ? screenWidth - 1 : screenWidth;
      const selector = type === 'none' ? '' : `(${type}-width ${width}px): `;

      return `${selector}${imageWidth}px`;
    })
    .join(',');
};

const calculateNextImageWidth = (
  sizes?: LeadsImageSize[],
  width?: number,
): number => {
  const suggestedWidth = (sizes ?? [])
    .filter((s) => {
      if (typeof window === 'undefined') {
        return false;
      }
      const windowWidth = window.innerWidth;
      switch (s.type) {
        case 'max':
          return windowWidth < s.screenWidth;
        case 'min':
          return windowWidth >= s.screenWidth;
        default:
          return false;
      }
    })
    .map((x) => x.imageWidth)
    .sort();

  return Math.min(suggestedWidth[0] ?? width4k, width ?? width4k);
};

const calculateNextImageParams = (
  sizes?: LeadsImageSize[],
  width?: number,
  quality: number = 100,
) => `&w=${calculateNextImageWidth(sizes, width)}&q=${quality}`;

const useImageLoader = (
  siteSlug: string,
  sizes?: LeadsImageSize[],
  useCors?: boolean,
) => {
  const assetPath = useAssetPath();
  const { isEmbeddableForms, publicRuntimeConfig } = useLeadsContext();
  const { appEnv } = publicRuntimeConfig;
  const isQa = appEnv === 'qa';

  return useCallback(
    (src: string, width?: number, quality: number = 100) => {
      if (isEmbeddableForms || useCors || isQa) {
        return src;
      }
      const next_params = calculateNextImageParams(sizes, width, quality);
      const url = encodeURIComponent(src);

      return `${assetPath}_next/image?market=${siteSlug}&url=${url}${next_params}`;
    },
    [assetPath, isEmbeddableForms, isQa, siteSlug, sizes, useCors],
  );
};

export const useNonProdEmbeddedFormsImageLoader = ({
  imageLoader,
  src,
  sizes,
}: LocalImageLoaderProps) => {
  const { isEmbeddableForms, publicRuntimeConfig, navigation } =
    useLeadsContext();
  const { authHeaderName, authHeaderValue, useCors, appEnv, apiHost } =
    publicRuntimeConfig;

  const isProd = appEnv === 'prod';
  const isDev = appEnv === 'dev';
  const isPrEnv = navigation.hostname.includes('86934291.commits.cloud');
  const isUsingLocalLoader =
    !isProd && (isEmbeddableForms || useCors || isPrEnv);

  src =
    isPrEnv && src.includes(navigation.hostname)
      ? src
          .replace('https://', '')
          .replace('http://', '')
          .replace(navigation.hostname, embeddableFormsApiBaseUrl)
      : src;

  const [imgSrc, setImgSrc] = useState(isUsingLocalLoader ? '' : src);

  useEffect(() => {
    if (!isUsingLocalLoader) return;

    let headers: Record<string, string> = {
      'VCC-Api-Operation': requestId(),
    };
    if (authHeaderName) {
      headers[authHeaderName] = authHeaderValue;
    }
    const fetchOptions: RequestInit = {
      mode: 'cors',
      cache: 'force-cache',
      headers,
      get signal() {
        return AbortSignal.timeout(publicRuntimeConfig.apiTimeout ?? 1500);
      },
    };

    const url = imageLoader(src, width4k, 100);

    const timeoutFallback = async (e?: Error) => {
      if (isDev) {
        const newSrc = src.replace(apiHost, embeddableFormsApiBaseUrl);
        return await fetch(newSrc, fetchOptions);
      }
      return e;
    };

    const notFoundFallback = (e?: Error) => {
      console.error(e);
      return fetch(src, fetchOptions)
        .then((r) => {
          if (r.status !== 200) {
            return timeoutFallback();
          }
          return r;
        })
        .catch(async (e) => await timeoutFallback(e));
    };

    fetch(url, fetchOptions)
      .then(async (r) => {
        if (r.status !== 200) {
          //Fallback request without next/image resize
          return await notFoundFallback();
        }
        return r;
      })
      .catch(async (e) => await notFoundFallback(e))
      .then((r) => {
        if (r instanceof Error || !r) {
          throw r ?? new Error('Image not found');
        }
        return r.blob();
      })
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        setImgSrc(blobUrl);
      })
      .catch((e) => {
        console.error('Error fetching image', e);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageLoader, sizes, src]);

  return { imgSrc, isUsingLocalLoader };
};

export const ModelImage = ({
  model,
  alt,
  sizes,
  type,
  className,
  imageClassName,
}: LeadsModelImageProps) => {
  const {
    siteSlug,
    isEmbeddableForms,
    publicRuntimeConfig: { useCors },
  } = useLeadsContext();
  const ref = createRef<HTMLElement>();
  const isVisible = useIsVisible(ref);
  const src = useModelImageSourceByType(
    model.presentation.images,
    type ?? VehicleImageType.MODEL_SIDE,
  );
  const imageLoader = useImageLoader(siteSlug, sizes, useCors);
  const { imgSrc, isUsingLocalLoader } = useNonProdEmbeddedFormsImageLoader({
    imageLoader,
    src,
    sizes,
  });

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  return (
    <figure className={cssMerge('relative aspect-3/2', className)}>
      <LeadsImage
        useCors={useCors}
        isEmbeddableForms={isEmbeddableForms}
        ref={ref}
        isVisible={isVisible}
        alt={alt ?? model.presentation.title ?? 'model image'}
        classNames={imageClassName}
        src={imgSrc}
        sizes={buildSizes(sizes)}
        loader={isUsingLocalLoader ? undefined : imageLoader}
        style={{
          objectFit: 'contain',
          width: '100%',
          height: '100%',
        }}
      />
    </figure>
  );
};

export const useVehicleImageUrl = (
  images: MarketVehiclePresentation['images'] | undefined,
  type: VehicleImageType,
  sizes?: LeadsImageSize[],
) => {
  const {
    purposeConfig,
    siteSlug,
    publicRuntimeConfig: { useCors },
    isEmbeddableForms,
  } = useLeadsContext();
  const tySrc = useModelThankYouImageSource(purposeConfig, images?.[type]);
  const modelSrc = useModelImageSourceByType(
    images ?? {},
    type ?? VehicleImageType.MODEL_SIDE,
  );
  const src = type === VehicleImageType.THANK_YOU ? tySrc : modelSrc;
  const imageLoader = useImageLoader(siteSlug, sizes, useCors);

  return {
    useCors,
    isEmbeddableForms,
    imageLoader,
    ...useNonProdEmbeddedFormsImageLoader({
      imageLoader,
      src,
    }),
  };
};

export const ThankYouImage = ({ image, sizes, alt }: LeadsTYImageProps) => {
  const images = {
    [VehicleImageType.THANK_YOU]: image,
  } as MarketVehiclePresentation['images'];
  const {
    imgSrc,
    isUsingLocalLoader,
    useCors,
    isEmbeddableForms,
    imageLoader,
  } = useVehicleImageUrl(images, VehicleImageType.THANK_YOU, sizes);

  return (
    <LeadsImage
      useCors={useCors}
      isEmbeddableForms={isEmbeddableForms}
      isVisible
      loader={isUsingLocalLoader ? undefined : imageLoader}
      src={imgSrc}
      alt={alt ?? 'Thank you'}
      style={{
        objectFit: 'cover',
        width: '100%',
        height: '100%',
      }}
    />
  );
};

type LeadsImageProps = {
  isEmbeddableForms: boolean;
  useCors: boolean;
  src: string;
  alt: string;
  sizes?: string;
  classNames?: string;
  isVisible: boolean;
  style?: React.CSSProperties;
  loader?: (src: string, width?: number, quality?: number) => string;
};

// eslint-disable-next-line react/display-name
const LeadsImage = forwardRef<HTMLElement, LeadsImageProps>((props, ref) => {
  const {
    isEmbeddableForms,
    useCors,
    isVisible,
    src,
    alt,
    sizes,
    classNames,
    style,
    loader,
  } = props;

  if (!src) {
    return <div className="flex flex-col items-center h-full bg-secondary" />;
  }

  const imgSrc = loader && !isEmbeddableForms && !useCors ? loader(src) : src;

  return (
    <img
      ref={ref as LegacyRef<HTMLImageElement>}
      loading={isVisible ? undefined : 'lazy'}
      alt={alt}
      className={cssMerge('bg-secondary block', classNames, 'w-full h-full')}
      src={imgSrc}
      sizes={sizes}
      style={style}
    />
  );
});
