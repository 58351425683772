'use client';
import React from 'react';
import ResponsiveGridHelper from '@vcc-www/responsive-grid-helper';
import {
  CalloutsOverlay,
  CalloutsOverlayProvider,
} from '@vcc-package/unique-selling-points';
import { ImageWithTextProps } from './ImageWithText.types';
import Ctas from './Ctas';
import Media from './Media';
import MainContent from './MainContent';
import Disclaimer from './Disclaimer';
import { ContentContainer, MainContainer } from './Containers';

/**
 * @deprecated This component is deprecated. Use clicable-discovery-card or discovery-card instead.
 */
const ImageWithText = (props: ImageWithTextProps) => {
  const {
    content: {
      title,
      subtitle,
      text,
      isMarkdown,
      readMoreTitle,
      badgeLabel,
      primaryCta,
      primaryCtaTrackLabel,
      primaryCtaTrackCategory,
      cta,
      ctaTrackLabel,
      ctaTrackCategory,
      image,
      iconTexts,
      disclaimer,
      overlayCallouts = [],
      overlayCta,
      overlayTitle,
      videoMp4,
      videoWebM,
      loopVideo,
      videos,
      primaryCtaAlternate,
    },
    buttonAccessibilityLabel,
    linkAccessibilityLabel,
    alignImage,
    alignDisclaimer,
    titleAlignment = 'left',
    textAlignment = 'left',
    ctasAlignment = 'left',
    imageAspectRatio,
    isBlue,
    disableAutoOptimize,
    primaryCtaVariant = 'outline',
    hasQuotes = false,
    reverseColumn = false,
    SectionContainedComponent,
    background,
    loop,
    autoplay,
    largePlayIcon,
    centeredPlayIcon,
    lazyLoadPoster,
    mobileFullWidthText,
    imageDensity,
    videoLoading,
  } = props;

  return (
    <CalloutsOverlayProvider>
      {({ openOverlay }) => (
        <div
          className={isBlue ? 'bg-surface-accent-blue' : undefined}
          data-color-mode={background}
        >
          <SectionContainedComponent>
            <MainContainer {...{ alignImage, reverseColumn }}>
              <Media
                {...{
                  videoMp4,
                  videoWebM,
                  loopVideo,
                  videos,
                  image,
                  loop,
                  autoplay,
                  largePlayIcon,
                  centeredPlayIcon,
                  lazyLoadPoster,
                  imageAspectRatio,
                  alignImage,
                  disableAutoOptimize,
                  imageDensity,
                  videoLoading,
                }}
              />
              <ContentContainer {...{ mobileFullWidthText, reverseColumn }}>
                <ResponsiveGridHelper
                  size={{ default: 3.72, onlyM: 5, onlyS: 12 }}
                  contentJustify={
                    alignImage === 'right' ? 'flex-end' : 'flex-start'
                  }
                >
                  <MainContent
                    {...{
                      titleAlignment,
                      textAlignment,
                      isBlue,
                      hasQuotes,
                      title,
                      subtitle,
                      text,
                      readMoreTitle,
                      isMarkdown,
                      iconTexts,
                      badgeLabel,
                    }}
                    ctas={
                      <Ctas
                        {...{
                          primaryCta,
                          primaryCtaTrackLabel,
                          primaryCtaTrackCategory,
                          cta,
                          ctaTrackLabel,
                          ctaTrackCategory,
                          overlayCallouts,
                          primaryCtaAlternate,
                          ctasAlignment,
                          primaryCtaVariant,
                          openOverlay,
                          buttonAccessibilityLabel,
                          linkAccessibilityLabel,
                        }}
                      />
                    }
                  />
                  <Disclaimer
                    className="flex lg:hidden"
                    isMobile
                    {...{
                      disclaimer,
                      alignDisclaimer,
                      isBlue,
                    }}
                  />
                </ResponsiveGridHelper>
              </ContentContainer>
            </MainContainer>
            <Disclaimer
              className="lg:flex hidden"
              isMobile={false}
              {...{
                disclaimer,
                alignDisclaimer,
                isBlue,
              }}
            />
            <CalloutsOverlay
              title={overlayTitle}
              callouts={overlayCallouts}
              cta={overlayCta}
            />
          </SectionContainedComponent>
        </div>
      )}
    </CalloutsOverlayProvider>
  );
};

export default ImageWithText;
