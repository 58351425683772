'use client';

import type { JSX } from 'react';
import React, { useEffect } from 'react';

import {
  firstTruthyElementIfArray,
  getModel,
} from '@vcc-package/leads-utils/util';

import { useLeadsContext } from '../../context/leadsContext';
import Page from '../Page';

import { ThankYouPageCta } from './ThankYouPageCta';
import { useTranslate } from '../../context';

export const ThankYouPage = (): JSX.Element => {
  const headingRef = React.createRef<HTMLHeadingElement>();

  const { purposeConfig, submitData, translationKeys, vehicles } =
    useLeadsContext();
  const translate = useTranslate();

  const { thankYouHeading, thankYouSubHeading = '' } = translationKeys;

  const pno = firstTruthyElementIfArray(submitData?.productsOfInterest);
  const model = getModel({ pno, vehicles });
  const ctaLink = model?.ctaLink ?? purposeConfig?.features?.ctaLink;
  const heading = translate.oldTranslator(
    thankYouHeading,
    model?.presentation.title || 'Volvo',
  );

  const subHeading = thankYouSubHeading && translate(thankYouSubHeading);

  useEffect(() => {
    if (headingRef.current) {
      headingRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [headingRef]);

  return (
    <Page className="items-center mb-32">
      <h1
        className="statement-3 font-medium"
        ref={headingRef}
        style={{ scrollMarginTop: 84 }}
      >
        {heading}
      </h1>
      {subHeading && <p>{subHeading}</p>}
      {ctaLink && <ThankYouPageCta ctaLink={ctaLink} />}
    </Page>
  );
};
