import { CarouselCarData } from '../types/carouselTypes';
import { CarouselGraphCarData } from '../types/graphTypes';

const carMapper = (
  car: CarouselGraphCarData,
  colorMapping: Record<string, string>,
): CarouselCarData => ({
  body:
    car?.carType?.content?.carTypeCategory?.formatted ||
    car?.carType?.content?.carTypeCategory?.value,
  bodyKey: car?.carType?.content?.carTypeCategory?.value,
  cashPrice: car.cashPrice?.price?.display,
  displayName: car?.model?.displayName?.value,
  engineType: car?.engine?.content.engineType?.formatted,
  engineTypeKey: car?.engine?.content.engineType?.value,
  image: getCarImage(car, colorMapping),
  key: car?.model?.key,
});

export default carMapper;

const getCarImage = (
  car: CarouselGraphCarData,
  colorMapping: Record<string, string>,
): string => {
  const fallbackImage =
    car?.visualizations?.[0]?.galleries?.custom?.images?.[0]?.small
      ?.transparentUrl;

  const carModelDictionaryKey = `CarColors.colorMapping.${car?.model?.key}`;
  const colorCode =
    carModelDictionaryKey && colorMapping[carModelDictionaryKey];

  if (!colorCode) {
    return fallbackImage;
  }

  const matchingConfig = car?.configuration?.colors?.find((colorConfig) =>
    colorConfig?.configurations?.some(
      (configuration) => configuration?.car?.color?.code === colorCode,
    ),
  );

  const matchingImage =
    matchingConfig?.configurations?.[0]?.car?.visualizations?.[0]?.galleries
      ?.custom?.images?.[0]?.small?.transparentUrl;

  return matchingImage || fallbackImage;
};
