'use client';
import * as React from 'react';

import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { useFormContext } from 'react-hook-form';
import type { B2BOptions } from '@vcc-package/leads-utils/types';
import { FlexibleFormSections } from '@vcc-package/leads-utils/types';
import type {
  CustomSection,
  DealerPickerSection,
  FlexFormConfiguration,
  FlexFormFieldType,
  KvkkSection,
  LegalSection,
  MainSection,
  VehiclePickerSection,
} from '@vcc-package/leads-utils/api';

import type { FieldValidations } from '../components/types';
import type { AdditionalProps } from '../flexibleForm';
import type { LoadingDebouncedType } from '../LeadsForm';
import { useLeadsContext } from '../../context/leadsContext';
import { applyOptinValidation, getValidatedOptins } from '../optinUtils';
import {
  normalizeRowValues,
  normalizeValidationKeys,
  validateFieldConfiguration,
  validateValidationConfiguration,
} from '../validateFormConfig';
import { useTranslate } from '../../context';

type MainSectionLegacyProp = MainSection & {
  b2b: B2BOptions;
  allowManualPhoneValidation: boolean;
  hideFields?: FlexFormFieldType[];
  validationRules: FieldValidations;
};

///////////////////////
// Context
///////////////////////
type FlexFormsContextValue = {
  sections?: string[];
  hideRequired?: boolean;
  loading?: boolean;
  setLoadingDebounced?: LoadingDebouncedType;
  customSubmitLabel?: string;
  useSingleColumn?: boolean;
  inOverlay?: boolean;
  sectionProps?: {
    dealerPickerLegacyProps: DealerPickerSection;
    mainSectionLegacyProps: MainSectionLegacyProp;
    legalSectionLegacyProps: LegalSection;
    kvkkSectionLegacyProps: KvkkSection;
    customSections: Record<string, CustomSection>;
    vehiclePickerProps: VehiclePickerSection;
  };
};

const FlexFormsContext = React.createContext<FlexFormsContextValue>({});

export const useFlexFormContext = () => React.useContext(FlexFormsContext);
export const useFlexFormDealerPickerContext = () =>
  useFlexFormContext().sectionProps?.dealerPickerLegacyProps;
export const useFlexFormVehiclePickerContext = () =>
  useFlexFormContext().sectionProps?.vehiclePickerProps;
export const useFlexFormMainSectionContext = () =>
  useFlexFormContext().sectionProps?.mainSectionLegacyProps;
export const useFlexFormKvkkSectionContext = () =>
  useFlexFormContext().sectionProps?.kvkkSectionLegacyProps;
export const useFlexFormLegalSectionContext = () =>
  useFlexFormContext().sectionProps?.legalSectionLegacyProps;
export const useFlexFormCustomSectionContext = (CustomKey: string) =>
  useFlexFormContext().sectionProps?.customSections[CustomKey];

///////////////////////
// Provider
///////////////////////
export type FlexibleFormProps = React.PropsWithChildren<{
  additionalProps?: AdditionalProps;
  loading?: boolean;
  setLoadingDebounced?: LoadingDebouncedType;
}>;

const FlexibleFormsProvider = ({ children, ...props }: FlexibleFormProps) => {
  //////////
  // Dependencies
  //////////
  const translate = useTranslate();
  const { formConfig, features, purpose, isEmbeddableForms } =
    useLeadsContext();
  const { watch } = useFormContext();

  if (isEmpty(formConfig) && !isEmbeddableForms) {
    throw new Error(
      `No FlexibleFormConfiguration (key: FormConfig) found in marketConfiguration. getFlexFormConfig failed for purpose: ${purpose}`,
    );
  }

  //////////
  // Util Setup
  //////////
  const mainRows = formConfig?.main?.rows ?? [];
  const normalizedMainRows = normalizeRowValues(mainRows);
  let validationMainRules = React.useMemo(
    () => ({ ...normalizeValidationKeys(formConfig?.main?.validation ?? {}) }),
    [formConfig?.main?.validation],
  );

  // If the form is using validated optins we update the connected field to be required
  const validatedOptins = React.useMemo(
    () => getValidatedOptins(formConfig),
    [formConfig],
  );

  if (validatedOptins.length) {
    // We need to watch the optins to ensure that we capture changed values in them
    const watchFields = watch(validatedOptins.map((vo) => vo.fieldName));
    validationMainRules = applyOptinValidation(
      validationMainRules,
      validatedOptins,
      watchFields,
    );
  }

  ///////////
  // Validate config
  ///////////
  validateFieldConfiguration(formConfig);
  validateValidationConfiguration(formConfig);

  //////////
  // Context value init
  //////////
  const flexFormsContextValue = React.useMemo<FlexFormsContextValue>(() => {
    const sections = buildSectionsOrder(formConfig, props.additionalProps);
    return {
      translate,
      sections,
      hideRequired: props.additionalProps?.hideRequired,
      loading: props.loading,
      setLoadingDebounced: props.setLoadingDebounced,
      customSubmitLabel: props.additionalProps?.customSubmitLabel,
      useSingleColumn:
        props.additionalProps?.useSingleColumn || features.useSingleColumn,
      sectionProps: {
        dealerPickerLegacyProps: {
          title: formConfig?.dealerPicker?.title,
          byAddress: formConfig?.dealerPicker?.byAddress ?? true,
          customTranslation: formConfig?.dealerPicker?.customTranslation,
        },
        vehiclePickerProps: formConfig?.vehiclePicker,
        mainSectionLegacyProps: {
          title: formConfig?.main?.title,
          options: formConfig?.main?.options,
          customTranslation: formConfig?.main?.customTranslation,
          rows: normalizedMainRows,
          allowManualPhoneValidation: features.allowManualPhoneValidation,
          b2b: props.additionalProps?.b2b,
          hideFields: props.additionalProps?.hideFields,
          validationRules: validationMainRules,
          validation: formConfig?.main.validation,
        },
        legalSectionLegacyProps: formConfig?.legal,
        kvkkSectionLegacyProps: formConfig?.kvkk,
        customSections: formConfig?.custom,
      },
      inOverlay: props.additionalProps?.inOverlay,
    } as FlexFormsContextValue;
  }, [
    formConfig,
    props.additionalProps,
    props.loading,
    props.setLoadingDebounced,
    translate,
    features.useSingleColumn,
    features.allowManualPhoneValidation,
    normalizedMainRows,
    validationMainRules,
  ]);

  return (
    <FlexFormsContext.Provider value={flexFormsContextValue}>
      {children}
    </FlexFormsContext.Provider>
  );
};

const isCustomSection = (section: string) => {
  return (
    section === FlexibleFormSections.Custom ||
    !(Object.values(FlexibleFormSections) as string[]).includes(section)
  );
};

const includeSection = (
  section: string,
  formConfig: FlexFormConfiguration,
  additionalProps?: AdditionalProps,
) => {
  if (section === FlexibleFormSections.DealerPicker) {
    return !additionalProps?.hideDealerPicker;
  }

  if (
    section?.toLowerCase() === FlexibleFormSections.VehiclePicker.toLowerCase()
  ) {
    return !!formConfig.vehiclePicker;
  }

  // If KVKK section is null => don't render it
  if (section === FlexibleFormSections.Kvkk && !formConfig.kvkk) {
    return false;
  }

  if (isCustomSection(section)) {
    const hasCustomSection = has(formConfig.custom, section);
    //Check section key matches a definition in the form config
    if (!hasCustomSection) {
      console.warn(
        `Found a section in order element that does not exist in custom (${section})`,
      );
    }
    return hasCustomSection;
  }

  return true;
};

const buildSectionsOrder = (
  formConfig: FlexFormConfiguration | null,
  additionalProps?: AdditionalProps,
): string[] => {
  const filteredOrder = formConfig?.order?.filter((section) =>
    includeSection(section, formConfig, additionalProps),
  );
  return filteredOrder ?? [];
};

export default FlexibleFormsProvider;
