'use client';

import { useCallback, useEffect, useMemo, useRef } from 'react';

import { getMarketSite } from '@volvo-cars/market-sites';
import type {
  Navigation,
  NavigationFactoryProps,
  Query,
} from '@vcc-package/leads-utils/types';
import { FlexibleFormSections } from '@vcc-package/leads-utils/types';
import type {
  FlexFormConfiguration,
  FullConfiguration,
  MarketVehicleConfiguration,
} from '@vcc-package/leads-utils/api';
import { PurposeType, TranslationKey } from '@vcc-package/leads-utils/api';
import type { PublicRuntimeConfig } from '@vcc-package/leads-utils/util';
import {
  getQueryObject,
  usePublicConfig,
  getLowerCasedQueryObject,
} from '@vcc-package/leads-utils/util';
import {
  defaultDealerPickerByAddress,
  getClientIdByFormVariant,
  LeadsApplications,
} from '@vcc-package/leads-utils/constants';

import type { EmbeddableFormsWrapperProps } from '../components/EmbeddableFormsWrapper';
import { getWindowBasedConfig } from '../utils';
import { LeadsApp } from '../constants';
import {
  LeadsContextProperties,
  LeadsExternalContextProps,
  LeadsExternalContextProviderProps,
} from '@vcc-package/leads-utils';
import { abTestingContextMapper } from '@vcc-package/leads-utils/abTesting';

type AppEnv = PublicRuntimeConfig['appEnv'];

const defaultConfig = {
  appEnv: 'LOCAL' as AppEnv,
  appId: LeadsApp.QuoteRequest,
  baseUrl: '',
  siteSlug: '',
  formParameters: {},
  ssrUrlData: {
    originalUrl: '',
    referralUrl: '',
  },
};

export const useLeadsContextSettings = (
  props: Omit<EmbeddableFormsWrapperProps, 'formType'> & { appId: LeadsApp },
): LeadsExternalContextProviderProps => {
  const {
    settings,
    consumerApp,
    appId,
    featureFlags,
    onExitIntentIntroductionComponent,
  } = props;

  useEffect(() => {
    if (!consumerApp) {
      throw new Error('Consumer app is required in embeddable forms');
    }
  }, [consumerApp]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const publicRuntimeConfig =
    usePublicConfig({
      isEmbeddableForms: true,
    }) ?? {};

  const queryObject = getQueryObject(
    new URLSearchParams(
      typeof global !== 'undefined' ? global?.window.location.search : '',
    ),
  );

  const fallbackSettings = useMemo(() => {
    const environmentConfig = {
      ...defaultConfig,
      ...(typeof window !== 'undefined' ? getWindowBasedConfig(appId) : {}),
    };
    const { siteSlug, ssrUrlData, pathname, route } = environmentConfig;

    return {
      publicRuntimeConfig,
      marketSite: getMarketSite(
        siteSlug,
      ) as LeadsExternalContextProviderProps['marketSite'],
      formParameters: queryObject as Query,
      ssrUrlData,
      pathname,
      route,
      fullConfig: {} as LeadsExternalContextProviderProps['fullConfig'],
      configMapperFn: props?.configMapperFn ?? ((ctx) => Promise.resolve(ctx)),
      isEmbeddableForms: true,
      siteSlug,
      isLoaded: false,
    };
  }, [appId, publicRuntimeConfig, queryObject, props?.configMapperFn]);

  const fetchedFullConfigRef = useRef<FullConfiguration | null>(null);
  const hasOngoingRequest = useRef<boolean>(false);

  const contextMapperWrapped: LeadsExternalContextProps['contextMapperFn'] =
    useCallback(
      async (
        context: LeadsContextProperties,
      ): Promise<LeadsContextProperties> => {
        const { appId, API, siteSlug, clientId: clientIdByContext } = context;
        const clientIdBySettings =
          settings?.clientId ??
          settings?.formParameters?.clientid ??
          fallbackSettings.formParameters.clientid ??
          clientIdByContext;

        let purpose =
          settings?.overridePurpose ??
          context.purpose ??
          LeadsApplications[appId].purpose;

        const hasEverything =
          !!fetchedFullConfigRef.current?.marketConfiguration;

        if (!hasEverything && !hasOngoingRequest.current) {
          hasOngoingRequest.current = true;
          const clientIdByFormVariant =
            onExitIntentIntroductionComponent ===
            'KeepMeUpdatedIntroductionPage'
              ? getClientIdByFormVariant(appId, siteSlug, 'simplified')
              : undefined;

          fetchedFullConfigRef.current = await API.getFullConfig({
            purpose,
            clientId: clientIdBySettings ?? clientIdByFormVariant,
          });
          context.fullConfig = fetchedFullConfigRef.current;
          context.isLoaded = true;
          context.setPurpose(context.purpose);
          hasOngoingRequest.current = false;
        }

        const marketConfiguration =
          fetchedFullConfigRef.current?.marketConfiguration ??
          context.marketConfig;

        context.marketConfig = marketConfiguration;
        context.features = {
          ...marketConfiguration.features,
          ...context.marketConfig.dynamicFeatures,
        };
        context.formConfigs =
          fetchedFullConfigRef.current?.flexFormConfigurations;
        context.vehiclesConfigs =
          fetchedFullConfigRef.current?.vehicleConfigurations;

        const hasInsideSalesRetailer =
          !!context.marketConfig?.insideSalesRetailer?.useInsideSalesTransform;

        if (
          !context.purpose &&
          (appId !== LeadsApp.QuoteRequest || !hasInsideSalesRetailer)
        ) {
          context.purpose = purpose;
          context.setPurpose(purpose);
        }

        if (context.purpose && fetchedFullConfigRef.current) {
          const isInsideSales =
            purpose === PurposeType.CBV_CALLBACK && hasInsideSalesRetailer;

          context.formConfigs =
            fetchedFullConfigRef.current?.flexFormConfigurations;

          context.formConfig = context.formConfigs?.[
            purpose
          ] as FlexFormConfiguration;

          context.vehicles = context.vehiclesConfigs?.[
            purpose
          ] as MarketVehicleConfiguration[];

          const hasDealerPicker = context.formConfig?.order.includes(
            FlexibleFormSections.DealerPicker,
          );

          //We should only force show the retailer picker if it is a QR form
          const shouldForceDealerPicker = appId === LeadsApp.QuoteRequest;

          if (shouldForceDealerPicker) {
            if (isInsideSales) {
              if (hasDealerPicker) {
                context.formConfig.dealerPicker = null;
              }
            } else if (!hasDealerPicker) {
              context.formConfig.main.title =
                TranslationKey.REDES_CONTACT_INFORMATION_HEADER;
              context.formConfig.dealerPicker = defaultDealerPickerByAddress;
              context.formConfig.order?.unshift(
                FlexibleFormSections.DealerPicker,
              );
            }
          }

          const shouldForceVehiclePicker = appId === LeadsApp.QuoteRequest;
          if (shouldForceVehiclePicker) {
            const paramValue =
              context.navigation.query.pno ?? context.navigation.query.modelkey;
            addSectionToFormConfig(
              context,
              paramValue,
              FlexibleFormSections.VehiclePicker,
              {
                required: false,
              },
            );
          }
        }

        context.purposeConfig =
          context.marketConfig.purposeConfigs && context.purpose
            ? context.marketConfig.purposeConfigs[context.purpose]
            : undefined;

        context.consumerApp = consumerApp;

        /* To apply AB-tests */
        await abTestingContextMapper(context);

        return fallbackSettings.configMapperFn
          ? await fallbackSettings.configMapperFn(context)
          : context;
      },
      [
        settings?.clientId,
        settings?.formParameters?.clientid,
        settings?.overridePurpose,
        fallbackSettings,
        consumerApp,
        onExitIntentIntroductionComponent,
      ],
    );

  const contextSettings = useMemo(
    () =>
      ({
        fullConfig: {} as any,
        marketSite: settings?.marketSite ?? fallbackSettings.marketSite,
        appId,
        ssrUrlData: {
          ...fallbackSettings.ssrUrlData,
          ...(settings?.ssrUrlData ?? {}),
        },
        clientId: settings?.clientId,
        contextMapperFn: contextMapperWrapped,
        overridePurpose: settings?.overridePurpose ?? null,
        isEmbeddableForms: true,
        inOverlay: settings?.inOverlay ?? false,
        consumerApp: settings?.consumerApp ?? null,
        queryObject,
        onExitIntentIntroductionComponent,
        ssrCookies: document.cookie,
        navigationFactory(navigationFactoryProps: NavigationFactoryProps) {
          let queryState: Query = {};
          return {
            onHashChange(callback) {
              if (typeof global === 'undefined') {
                return;
              }
              global.addEventListener('hashchange', callback);

              return () => {
                global.removeEventListener('hashchange', callback);
              };
            },
            get hashValue() {
              return global.location?.hash ?? '';
            },
            goTo(url, options) {
              //TODO: Better handling of navigation
              console.info(
                'Navigation not implemented in embeddable forms',
                url,
                options,
              );
            },
            get query() {
              const browserQuery: Query = getQueryObject(
                new URLSearchParams(
                  typeof global !== 'undefined'
                    ? global?.window.location.search
                    : '',
                ),
              );
              const newQuery: Query = getLowerCasedQueryObject({
                ...navigationFactoryProps.query,
                ...(settings?.formParameters ?? {}),
                ...(!featureFlags?.ignoreUrlQueryAsFormParameters
                  ? browserQuery
                  : {}),
                ...queryState,
              });
              const oldQuery = getLowerCasedQueryObject(
                navigationFactoryProps.query,
              );

              const queryHasChanged =
                JSON.stringify(newQuery) !== JSON.stringify(oldQuery);

              if (queryHasChanged) {
                queryState = { ...newQuery };
              }

              return newQuery;
            },
            get pathname() {
              return fallbackSettings.pathname;
            },
            get route() {
              return this.pathname.replace(
                `/${fallbackSettings.siteSlug}/`,
                '/[market]/',
              );
            },
            get hostname() {
              return typeof global !== 'undefined'
                ? global.location?.hostname
                : 'localhost';
            },
            get origin() {
              return typeof global !== 'undefined'
                ? global.location?.origin
                : 'http://localhost:3000';
            },
            isClientSideNavigation: true,
            clearSelectedRetailerQuery() {
              console.info('Clearing selected retailer');
            },
            clearSelectedModelQuery() {
              console.info('Clearing selected model');
            },
          } as Navigation;
        },
      }) as LeadsExternalContextProviderProps,
    [
      settings?.marketSite,
      settings?.ssrUrlData,
      settings?.clientId,
      settings?.overridePurpose,
      settings?.inOverlay,
      settings?.consumerApp,
      settings?.formParameters,
      fallbackSettings.marketSite,
      fallbackSettings.ssrUrlData,
      fallbackSettings.pathname,
      fallbackSettings.siteSlug,
      appId,
      contextMapperWrapped,
      queryObject,
      onExitIntentIntroductionComponent,
      featureFlags?.ignoreUrlQueryAsFormParameters,
    ],
  );

  return contextSettings;
};

type ValidSection = keyof Pick<FlexFormConfiguration, 'vehiclePicker'>;

const addSectionToFormConfig = (
  context: LeadsContextProperties,
  queryParamValue: string = '',
  section: ValidSection,
  config: Record<string, string | boolean | number>,
) => {
  if (!context.formConfig) return;

  const formConfigHasSection = context.formConfig[section];

  if (queryParamValue && formConfigHasSection) {
    context.formConfig[section] = null;
    context.formConfig.order = context.formConfig.order.filter(
      (x) => x !== section,
    );
  } else if (!queryParamValue && !formConfigHasSection) {
    context.formConfig[section] = { required: false, ...config };
    context.formConfig.order.unshift(section);
  }
};
