'use client';
import React from 'react';

import type { TextAreaProps } from '@volvo-cars/react-forms';
import type { Control, FieldErrors } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { TextArea } from '@volvo-cars/react-forms';

import type { Messages } from '../types';
import { useRequiredLabel } from '../../../../hooks/useRequiredLabel';

type FieldProps = {
  name: string;
  testid?: string;
  label: string;
  control: Control;
  defaultValue?: string;
  autoComplete?: TextAreaProps['autoComplete'];
  fieldType?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  errors?: FieldErrors;
  messages: Messages;
  hideRequired?: boolean;
  description?: string;
};

export const TextAreaField = ({
  name,
  testid,
  defaultValue = '',
  label,
  autoComplete,
  control,
  required = false,
  minLength = 0,
  maxLength, // Defaults does not work for this prop, since maxLength is a number and is 0 when "unset".
  messages,
  hideRequired = false,
}: FieldProps) => {
  const formattedLabel = useRequiredLabel(label, required, hideRequired, false);
  maxLength = !maxLength || maxLength === 0 ? 255 : maxLength;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={{
        required: {
          value: required,
          message: messages?.required ? messages.required(label) : '',
        },
        minLength: {
          value: minLength,
          message: messages?.length
            ? messages.length(label, minLength, maxLength)
            : '',
        },
        maxLength: {
          value: maxLength,
          message: messages?.length
            ? messages.length(label, minLength, maxLength)
            : '',
        },
      }}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error },
      }) => (
        <TextArea
          ref={ref}
          name={name}
          data-testid={testid}
          label={formattedLabel}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          autoComplete={autoComplete}
          errorMessage={error?.message}
          spellCheck={false}
        />
      )}
    />
  );
};
