'use client';
import React, { useEffect, useState } from 'react';

import type { FieldProps } from '@vcc-package/leads-utils/types';
import { getAllPhoneAreaCodeData } from '@vcc-www/utils/getPhoneAreaCodes';
import { getInputErrorMessages, useValidation } from '../../../hooks';
import { useLeadsContext, useTranslate } from '../../context';
import { Select, TelInput } from '@volvo-cars/react-forms';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { getTranslationKey } from '../../../util';
import { FlexFormFieldType, TranslationKey } from '../../../api';

const areaCodeData = getAllPhoneAreaCodeData();

const generateAreaCodeOptions = () =>
  Object.entries(areaCodeData).map((entry) => {
    const [country, { areaCodes }] = entry;

    return areaCodes.map((ac) => (
      <option value={`+${ac}`} key={`${country}-${ac}`}>
        {`${country.toUpperCase()} +${ac}`}
      </option>
    ));
  });

const getAreaCodeFromCountryCode = (countryCode: string) => {
  return (
    Object.entries(areaCodeData).find(
      ([x]) => x?.toLowerCase() === countryCode?.toLowerCase(),
    )?.[1].areaCodes ?? []
  );
};

export const PhoneWithCountryCodeField = (props: FieldProps): JSX.Element => {
  const { validation, customTranslation, hideRequired } = props;
  const translate = useTranslate();
  const { siteSlug } = useLeadsContext();
  const form = useFormContext();
  const countryCode = siteSlug.includes('-')
    ? siteSlug.split('-')[1].toLowerCase()
    : siteSlug;
  const defaultAreaCodes = getAreaCodeFromCountryCode(countryCode);

  const { minLength, maxLength, regexValidate } = useValidation(
    validation?.mobilephone,
  );

  const minLengthValue = minLength ?? 0;
  const maxLengthValue = maxLength ?? 25;

  const required = true;

  const areaCodeOptions = generateAreaCodeOptions();

  const { control } = useFormContext();
  const messages = getInputErrorMessages(translate);
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormFieldType.MOBILE_PHONE,
  );

  const label = customTranslationKeys
    ? translate(customTranslationKeys.label)
    : translate(TranslationKey.PHONE_NUMBER_FIELD_LABEL);

  const rules: RegisterOptions = {
    required: {
      value: required ?? false,
      message: messages?.required ? messages.required(label) : '',
    },
    minLength: {
      value: minLengthValue,
      message: messages?.length
        ? messages.length(label, minLengthValue, maxLengthValue)
        : '',
    },
    maxLength: {
      value: maxLengthValue,
      message: messages?.length
        ? messages.length(label, minLengthValue, maxLengthValue)
        : '',
    },
    validate: async (value: string) => {
      if (!value && !required) return true;

      if (value === selectedCountryCode) {
        return messages.required ? messages.required(label) : false;
      }

      const success = await regexValidate(value);

      return success
        ? true
        : messages?.invalid
          ? messages.invalid?.(label)
          : false;
    },
  };

  const [selectedCountryCode, setSelectedCountryCode] =
    React.useState<string>('');

  const defaultPhoneNumberValue = (phoneNumber = '') => {
    if (phoneNumber) {
      const startsWithDefaultCountryCode = phoneNumber.startsWith(
        `+${defaultAreaCodes[0]}`,
      );

      if (startsWithDefaultCountryCode) {
        return phoneNumber.replace(`+${defaultAreaCodes[0]}`, '');
      }
    }

    return phoneNumber;
  };

  const [phoneNumberValue, setPhoneNumberValue] = useState<string>(
    defaultPhoneNumberValue(form.getValues('MobilePhone')),
  );

  const onSelectedCountryCodeChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    onChange: (...event: any[]) => void,
  ) => {
    const phoneNumber = `${e.target.value}${phoneNumberValue}`;

    setSelectedCountryCode(e.target.value);
    onChange(phoneNumber);
  };

  const onTextInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    onChange: (...event: any[]) => void,
  ) => {
    const phoneNumber = e.target.value.startsWith('0')
      ? e.target.value.substring(1)
      : e.target.value;

    const phoneNumberWithAreaCode = `${selectedCountryCode}${phoneNumber}`;

    setPhoneNumberValue(e.target.value);
    onChange(phoneNumberWithAreaCode);
  };

  useEffect(() => {
    setSelectedCountryCode(`+${defaultAreaCodes[0]}`);
  }, [defaultAreaCodes]);

  return (
    <Controller
      control={control}
      name={FlexFormFieldType.MOBILE_PHONE}
      rules={rules}
      render={({
        field: { onChange, onBlur, name, ref },
        fieldState: { error },
      }) => {
        return (
          <div
            className="grid gap-16"
            style={{
              gridTemplateColumns: 'auto 1fr',
            }}
          >
            <Select
              label={translate(TranslationKey.COUNTRY_CODE)}
              name={TranslationKey.COUNTRY_CODE}
              required={required && !hideRequired}
              defaultValue={`+${defaultAreaCodes[0]}`}
              onChange={(e) => onSelectedCountryCodeChange(e, onChange)}
            >
              {areaCodeOptions}
            </Select>
            <TelInput
              label={label}
              onChange={(e) => onTextInputChange(e, onChange)}
              onBlur={onBlur}
              value={phoneNumberValue}
              name={name}
              ref={ref}
              errorMessage={error?.message}
              autoComplete="tel"
            />
          </div>
        );
      }}
    />
  );
};
