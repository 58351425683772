'use client';

import React from 'react';
import { useLeadsContext } from '../../context/leadsContext';
import { QuoteFormThankYouPage } from './QuoteFormThankYouPage';
import { ThankYouPage } from './ThankYouPage';
import {
  DiscoveryCardHorizontal,
  DiscoveryCardStacked,
} from '@vcc-package/discovery';
import { useModel } from '../../context/modelContext';
import { VehicleImageType } from '@vcc-package/leads-utils/api';
import { useTranslate } from '../../context';
import { useVehicleImageUrl } from '../imageComponents/LeadsImages';

import { Spinner } from '../Spinner';
import styles from './masterThankYouPage.module.css';

export type MasterThankYouPageProps = Record<string, unknown>;
export const MasterThankYouPage = (props: MasterThankYouPageProps) => {
  const { appId, features, purpose, isLoaded, isEmbeddableForms } =
    useLeadsContext();
  const { selectedModel } = useModel();

  const { imgSrc } = useVehicleImageUrl(
    selectedModel?.presentation.images,
    VehicleImageType.THANK_YOU,
  );

  const translate = useTranslate();

  const heading =
    purpose === 'KEEP_ME_UPDATED'
      ? translate('KMU_ThankYouBodyText')
      : selectedModel?.presentation.title
        ? translate('TitleThanksForYourInterest')
        : translate('TitleThanksForYourRequest');

  let bodyText;

  switch (purpose) {
    case 'CBV_CALLBACK':
      bodyText = translate('CB_ThankYouBodyText');
      break;
    case 'CONTACT_REQUEST':
      bodyText = translate('ThankYouBodyText');
      break;
    case 'OFFER_REQUEST':
      bodyText = translate('ThankYouBodyText');
      break;
    default:
      bodyText = '';
      break;
  }

  if (!isLoaded) {
    const extraStyles = isEmbeddableForms
      ? undefined
      : {
          height: '100vh',
        };
    return <Spinner style={extraStyles} />;
  }

  if (!features?.NewThankYouPage) {
    if (appId === 'quote') {
      return <QuoteFormThankYouPage {...props} />;
    }
    return <ThankYouPage />;
  }

  const responsiveImages = {
    sm: {
      src: imgSrc,
      alt: 'placeholder',
    },
  };

  return (
    <div data-testid="thank-you-page-root">
      {isEmbeddableForms ? (
        <DiscoveryCardStacked
          responsiveImages={responsiveImages}
          heading={heading}
          bodyText={bodyText}
          className={styles['discovery-card']}
        />
      ) : (
        <div className="py-16">
          <DiscoveryCardHorizontal
            layout="reversed"
            responsiveImages={responsiveImages}
            heading={heading}
            bodyText={bodyText}
            className={styles['discovery-card']}
          />
        </div>
      )}
    </div>
  );
};
