import React from 'react';
import DiscoveryCardPartContent from './discovery-card.part.content';
import DiscoveryCard from '.';
import {
  CardImageType,
  DiscoveryCardContentType,
  DiscoveryCardTemplateProps,
} from '../utils/types';
import { cssMerge } from '@volvo-cars/css/utils';

type Props = DiscoveryCardTemplateProps<
  CardImageType & {
    layout?: 'reversed' | 'default';
  }
>;

export const DiscoveryCardHorizontal = ({
  responsiveImages,
  layout,
  children,
  className,
  ...rest
}: Props) => {
  return (
    <DiscoveryCard
      variant="horizontal"
      layout={layout}
      responsiveImages={responsiveImages}
      className={cssMerge('md:gap-24', className)}
    >
      {children ? (
        <div slot="main">{children}</div>
      ) : (
        <DiscoveryCardPartContent {...(rest as DiscoveryCardContentType)} />
      )}
    </DiscoveryCard>
  );
};
