'use client';
import { useEffect, useState } from 'react';

import type { Translations } from '@vcc-package/leads-utils/types';
import {
  getSiteSlugByWindowLocation,
  getTranslationsObject,
  type OptionalPublicRuntimeConfig,
  usePublicConfig,
} from '@vcc-package/leads-utils/util';

import { APIClient } from '../util';

import { useCreateTranslator } from '.';

export function useTranslations() {
  const [translations, setTranslations] = useState<Translations | undefined>();
  const publicRuntimeConfig = usePublicConfig({
    isEmbeddableForms: true,
  });

  useEffect(() => {
    (async () => {
      setTranslations(await getTranslations(publicRuntimeConfig));
    })();
  }, [publicRuntimeConfig]);

  const t = useCreateTranslator({
    dictionary: (translations ?? {}) as Translations,
    hideMissingTranslationMessage:
      publicRuntimeConfig?.hideMissingTranslationMessage === 'true',
  });

  return {
    t,
  };
}

async function getTranslations(
  publicRuntimeConfig: OptionalPublicRuntimeConfig,
): Promise<Translations | undefined> {
  if (!publicRuntimeConfig) return;

  const apiClient = APIClient({
    appId: null as any,
    siteSlug: getSiteSlugByWindowLocation(),
    publicRuntimeConfig,
    ssrCookies: undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  });

  return getTranslationsObject(await apiClient.getTranslations());
}
